import React from "react";
import { Avatar, Group, Tooltip } from "@mantine/core";
import { useGlobal } from "../context/global-context";

export default function CountryPicker() {
  const { setContextValue, globalState } = useGlobal();

  const handleCountrySelect = (value) => {
    setContextValue("idmaxCountry", value);
  };

  const data = [
    {
      label: "United States",
      value: "US",
      emoji: "🇺🇸",
    },
    {
      label: "Canada",
      value: "CA",
      emoji: "🇨🇦",
    },
    {
      label: "United Kingdom",
      value: "UK",
      emoji: "🇬🇧",
    },
    {
      label: "India",
      value: "IN",
      emoji: "🇮🇳",
    },
  ];

  return (
    <Group>
      {data.map((country) => (
        <Tooltip key={country.value} label={country.label} withArrow>
          <Avatar
            onClick={() => handleCountrySelect(country.value)}
            style={{ cursor: "pointer" }}
            variant={
              globalState.idmaxCountry === country.value
                ? "outline"
                : "transparent"
            }
            alt={country.label}
            radius="xl"
          >
            {country.emoji}
          </Avatar>
        </Tooltip>
      ))}
    </Group>
  );
}
