import React from "react";
import {
  Paper,
  Text,
  Image,
  Box,
  Button,
  SimpleGrid,
  Stack,
  Group,
  Flex,
  Center,
  TextInput,
  Tabs,
  Grid,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import VerificationModal from "../../components/verification-modal";
import Acme from "../../assets/acme-logo.png";
import Banner from "../../assets/reg-banner.png";
import CreateIDMaxButtons from "./idmax-buttons";
//import FinTechTwo from "../../assets/fintech_two.png";
//import FinTechThree from "../../assets/fintech_three.png";
import CountryPicker from "../../components/country-picker";
export default function Register() {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <Grid>
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <Box h={"100vh"} style={{ backgroundColor: "white" }}>
            <Flex h={"100%"} justify="center" align="center" direction="column">
              <Paper
                style={{ width: "500px" }}
                h={"90%"}
                p={{ base: 50, sm: 6 }}
              >
                <Group justify="flex-end">
                  <CountryPicker />
                </Group>
                <Stack justify="center" gap={0} mt="md">
                  <Text fz={45} align="left" fw={700}>
                    Welcome
                  </Text>
                  <Text fz="lg" c="dimmed" align="left">
                    Start verifying yourself to register or choose a trusted
                    express option
                  </Text>
                </Stack>

                <Stack mt={50}>
                  <TextInput size="md" placeholder="Email Address" />
                  <Button
                    size="md"
                    fullWidth
                    onClick={open}
                    color="indigo"
                    variant=""
                  >
                    GET STARTED
                  </Button>
                  <Text align="center">or</Text>

                  <CreateIDMaxButtons />
                </Stack>
              </Paper>
            </Flex>
          </Box>
        </Grid.Col>
        <Grid.Col span={6} visibleFrom="sm">
          <Box h={"100vh"}>
            <Image radius="lg" src={Banner} h="100%" w="100%" />
          </Box>
        </Grid.Col>
      </Grid>

      <VerificationModal
        title="Verify Your Identity"
        description="You must first verify your identity before you can create an account"
        opened={opened}
        close={close}
      />
    </>
  );
}
