import { useState, useEffect } from "react";
import {
  Group,
  Text,
  InputBase,
  Combobox,
  useCombobox,
  Image,
  Box,
} from "@mantine/core";

function SelectOption({ emoji, label, description, displayMode }) {
  return (
    <Group wrap="nowrap">
      {displayMode === "emoji" && emoji}
      {displayMode === "full" && (
        <>
          <Box style={{ minWidth: "48px", width: "48px" }}>{emoji}</Box>

          <Box>
            <Text size="sm" fw={500}>
              {label}
            </Text>
            <Text size="xs" c="dimmed">
              {description}
            </Text>
          </Box>
        </>
      )}
      {displayMode === "labelOnly" && (
        <Text size="sm" weight={500}>
          {label}
        </Text>
      )}
    </Group>
  );
}

export default function ComboboxDropdown({
  data,
  defaultValue,
  onChange,
  onDropdownChange,
  withBorder = true,
  showLabelOnSelected = false,
}) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    console.log("combobox dropdown opened", combobox.dropdownOpened);
    if (onDropdownChange) onDropdownChange(combobox.dropdownOpened);
  }, [combobox.dropdownOpened, onDropdownChange]);

  const handleOptionSubmit = (value) => {
    setSelectedValue(value);
    combobox.closeDropdown();
    if (onChange) onChange(value);
  };

  const options = data.map((item) => (
    <Combobox.Option
      value={item.value}
      key={item.value}
      onClick={() => handleOptionSubmit(item.value)}
    >
      <SelectOption {...item} displayMode="full" />
    </Combobox.Option>
  ));

  const inputBaseStyles = {
    input: !withBorder
      ? {
          border: "none",
          height: "55px",
          "&:focus": {
            outline: "none",
            boxShadow: "none",
          },
        }
      : {},
  };

  const selectedOption = data.find((item) => item.value === selectedValue);

  return (
    <Combobox
      store={combobox}
      //withinPortal={false}
      transitionProps={{ duration: 400, transition: "pop" }}
      position="right"
      zIndex={5000}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          type="button"
          pointer
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents="none"
          onClick={() => combobox.toggleDropdown()}
          styles={inputBaseStyles}
        >
          {selectedOption ? (
            <SelectOption
              {...selectedOption}
              displayMode={!showLabelOnSelected ? "emoji" : "labelOnly"}
            />
          ) : (
            <Text>Make a selection</Text>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown
        styles={{
          dropdown: {
            minWidth: 350, // Set the minimum width of the dropdown
          },
        }}
      >
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
