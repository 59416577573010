import React from "react";
import { Route, Routes } from "react-router-dom";
import WineStore from "./pages/wine-store/home.js";
import Fintech from "./pages/fintech/home.js";
import OrderScreen from "./pages/wine-store/order-screen.js";
import KidsGames from "./pages/kids-games/index.js";
import Register from "./pages/register/register.js";
const RouteConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<WineStore />} />
      <Route path="/wine" element={<WineStore />} />
      <Route path="/fintech" element={<Fintech />} />
      <Route path="/register" element={<Register />} />
      <Route path="/kids-games" element={<KidsGames />} />
      <Route path="/result/:status" element={<OrderScreen />} />
    </Routes>
  );
};

export default RouteConfig;
