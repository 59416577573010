import React, { useState } from "react";
import {
  useMantineTheme,
  Box,
  Modal,
  ActionIcon,
  Tooltip,
  Paper,
  Group,
  Stack,
  Text,
  SimpleGrid,
  MultiSelect,
} from "@mantine/core";
import {
  IconChevronRight,
  IconCalendarBolt,
  IconId,
  IconPhoneCheck,
  IconFaceId,
} from "@tabler/icons-react";
import { useGlobal } from "../context/global-context";

export default function RouteModal() {
  const { globalState, setContextValue } = useGlobal();
  const theme = useMantineTheme();
  const [selectedTypes, setSelectedTypes] = useState([]);

  const iconMap = {
    "data match": <IconCalendarBolt color="teal" />,
    "identity document": <IconId color="teal" />,
    phone: <IconPhoneCheck color="teal" />,
    face: <IconFaceId color="teal" />,
  };

  const getIcons = (types) => {
    return types
      .map((type) => iconMap[type] || null)
      .filter((icon) => icon !== null);
  };

  const handleClick = (value, title) => {
    setContextValue("journey", value);
    setContextValue("journeyTitle", title);
    setContextValue("isRouteModalOpened", false);
  };

  // Extract types from journeys for MultiSelect options
  const types = Array.from(
    new Set(globalState.journeys.flatMap((j) => j.types))
  );

  // Filter journeys based on selected types
  const filteredJourneys = globalState.journeys
    .filter(
      (journey) =>
        selectedTypes.length === 0 ||
        journey.types.some((type) => selectedTypes.includes(type))
    )
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <Modal
      opened={globalState.isRouteModalOpened}
      title="Select a Route"
      onClose={() => setContextValue("isRouteModalOpened", false)}
      centered
      size="xl"
      radius={"md"}
    >
      <Box mb="md">
        <MultiSelect
          data={types}
          value={selectedTypes}
          onChange={setSelectedTypes}
          placeholder="Filter by type"
          clearable
        />
      </Box>
      <SimpleGrid cols={{ base: 1, sm: 2 }} pb="xl">
        {filteredJourneys.map((journey, index) => (
          <Paper key={index} withBorder p="md" mt="md">
            <Stack gap="lg">
              <Group wrap="nowrap" justify="space-between">
                <Group gap={4}>{getIcons(journey.types)}</Group>
                <Tooltip label="Select this route" position="top" withArrow>
                  <ActionIcon
                    size="md"
                    variant="light"
                    onClick={() => handleClick(journey.value, journey.title)}
                  >
                    <IconChevronRight size={20} stroke={0.5} />
                  </ActionIcon>
                </Tooltip>
              </Group>

              <Stack gap={0}>
                <Text fw={700}>{journey.title}</Text>
                <Text fz="sm" c="dimmed">
                  {journey.description}
                </Text>
              </Stack>
            </Stack>
          </Paper>
        ))}
      </SimpleGrid>
    </Modal>
  );
}
