import React, { useState, useEffect } from "react";
import {
  Paper,
  Text,
  Box,
  Button,
  Group,
  Divider,
  Container,
  Badge,
  Timeline,
  Stack,
  SimpleGrid,
  Code,
  Modal,
  UnstyledButton,
  Accordion,
  Loader,
  Indicator,
  Avatar,
  ThemeIcon,
  Flex,
  Tooltip,
  Grid,
  LoadingOverlay,
} from "@mantine/core";
import { useNavigate, useParams, useLocation } from "react-router-dom"; // Import useNavigate
import {
  IconAnalyze,
  IconCheck,
  IconChevronCompactDown,
  IconChevronCompactRight,
  IconChevronRight,
  IconClock,
  IconExclamationMark,
  IconHandStop,
  IconPhoneCheck,
  IconTargetArrow,
  IconUserBolt,
  IconX,
} from "@tabler/icons-react";
import Lottie from "react-lottie-player";
import lottieSuccess from "../../assets/success.json";
import lottieFailure from "../../assets/failure.json";
import { useGlobal } from "../../context/global-context";
export default function OrderScreen({ orderDetails }) {
  const { globalState, setContextValue } = useGlobal();
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Initialize useLocation
  const [data, setData] = useState(null);
  const [addOnData, setAddOnData] = useState(null);
  const { status } = useParams();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAddOnModalOpen, setAddOnModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  const fetchStatus = async () => {
    setIsLoading(true);
    //setError("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/demo/dcams/get-status.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data_returned = await response.json();
      if (data_returned) {
        setTimeout(() => {
          setData(data_returned);
          setIsLoading(false);
        }, 3000); // Delay in milliseconds (3000ms = 3s)
      }
    } catch (error) {
      //setError("Failed to fetch data: " + error.message);
    } finally {
      //setIsLoading(false);
    }
  };

  const collectCheckedaddons = (addons) => {
    const checkedKeys = [];
    Object.keys(addons).forEach((key) => {
      if (addons[key].checked) {
        checkedKeys.push(key);
      }
    });
    return checkedKeys;
  };

  const fetchaddons = async () => {
    //setIsLoading(true);
    //setError("");
    console.log("Fetching add-ons...");
    data.age = "21+";
    data.ssn = "";
    if (globalState.addons) {
      const checkedAddOnKeys = collectCheckedaddons(globalState.addons);
      // ... rest of your fetchaddons code

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/demo/add-ons/get-addons.php`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              addons: checkedAddOnKeys,
              data: data,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        if (responseData) {
          console.log(responseData);
          setAddOnData(responseData);
        }
      } catch (error) {
        console.log("there is an issue");
        //setError("Failed to fetch data: " + error.message);
      } finally {
        //setIsLoading(false);
      }
    } else {
      console.log("Add-ons object is not defined.");
    }
  };

  useEffect(() => {
    setContextValue("isSidebarShown", false);
    console.log("global state in the result page", globalState);
  }, []);

  useEffect(() => {
    // This will now trigger when `data` is first set to a non-null value,
    // and when `globalState.addons` is available
    if (data && globalState.addons) {
      console.log(
        "Data is ready and add-ons are available. Fetching add-ons..."
      );
      fetchaddons();
    } else {
      console.log("Waiting for data and/or add-ons to be ready...");
    }
  }, [data, globalState.addons]);

  const DataDisplay = ({ label, value }) => (
    <Paper p="sm" withBorder style={{ backgroundColor: "#fafafa" }}>
      <Stack spacing={0}>
        <Text c="dimmed" fz="xs" style={{ lineHeight: ".5" }}>
          {label}
        </Text>
        <Text style={{ lineHeight: ".5" }}>{value || "Not Collected"}</Text>
      </Stack>
    </Paper>
  );

  const AddOnIndicator = ({ addonName, label, icon }) => {
    const { result, detail, output } = addOnData?.[addonName] || {};
    const isAddOnChecked = globalState.addons[addonName]?.checked; // Check if the add-on is checked

    let displayData;
    let indicatorColor;
    let indicatorLabel;

    // When addOnData is not yet available, display "Loading..."
    if (!addOnData) {
      displayData = "Loading...";
      indicatorColor = "gray";
      indicatorLabel = "";
    } else if (!addOnData[addonName] && addOnData) {
      // When add-on is not active, show "NOT ACTIVE"
      displayData = "NOT ACTIVE";
      indicatorColor = "yellow";
      indicatorLabel = "!";
    } else {
      // When add-on is checked and active, show the relevant data

      if (addonName === "vPin") {
        displayData = output?.vpin?.uuid;
      } else if (addonName == "fraudScore") {
        displayData = output?.signals?.identity?.network_score
          ? output?.signals?.identity?.network_score + " out of 100"
          : "Missing Required Input";
      } else {
        displayData = result?.detail
          ? result?.detail
          : "Missing Required Input";
      }
      if (result?.detail === "ALL CHECKS PASSED") {
        indicatorColor = "teal";
        indicatorLabel = "+";
      } else if (addonName === "vPin" && output?.vpin?.uuid) {
        indicatorColor = "teal";
        indicatorLabel = "+";
      } else {
        indicatorColor = "red";
        indicatorLabel = "x";
      }
    }

    return (
      <Group>
        <Indicator
          color={indicatorColor}
          size={15}
          inline={true}
          label={indicatorLabel}
        >
          <ThemeIcon size={30} variant="outline">
            {icon}
          </ThemeIcon>
        </Indicator>
        <Stack gap={0}>
          <Text fz="sm">{label}</Text>
          <Text fz="sm" fw={700}>
            {displayData}
          </Text>
        </Stack>
      </Group>
    );
  };

  const journeyTimeline = (data) =>
    data.journey.stops && (
      <>
        <Group justify="space-between" mb="lg">
          <Text fz="lg" fw={500}>
            Journey
          </Text>

          <Tooltip label="Journey Duration">
            <Badge variant="light" padding="md">
              <Group gap={3}>
                <IconClock size={14} />
                {data.journey.duration}
              </Group>
            </Badge>
          </Tooltip>
        </Group>

        <Timeline
          color="teal"
          active={data.journey.stops.length || 0}
          bulletSize={24}
          lineWidth={2}
          mt="xl"
        >
          {data.journey.stops.map((journeyItem, index) => (
            <React.Fragment key={index}>
              <Timeline.Item
                bullet={
                  journeyItem.status === "PASS" ? (
                    <IconCheck size={22} color="green" />
                  ) : journeyItem.status === "FAIL" ? (
                    <IconX size={22} color="red" />
                  ) : (
                    <IconExclamationMark size={22} color="yellow" />
                  )
                }
                title={
                  <Group justify="space-between">
                    <Text fz="md" fw={700}>
                      {journeyItem.source === ""
                        ? "Session Init"
                        : journeyItem.source}
                    </Text>
                    <Badge
                      size="lg"
                      //autoContrast={true}
                      color={
                        journeyItem.status === "PASS" ||
                        journeyItem.status === null
                          ? "green"
                          : journeyItem.status === "FAIL"
                          ? "red"
                          : "yellow"
                      }
                    >
                      {journeyItem.status === null
                        ? "INIT"
                        : journeyItem.status}
                    </Badge>
                  </Group>
                }
              >
                <Stack gap={0}>
                  <Group>
                    <Text fz="sm" c="dimmed">
                      {journeyItem.detail}
                    </Text>
                  </Group>
                  <Text fz="xs" color="dimmed">
                    {journeyItem.timestamp}
                  </Text>
                </Stack>
              </Timeline.Item>
            </React.Fragment>
          ))}
        </Timeline>
        <Box align="center" mt="lg">
          <UnstyledButton size="sm" onClick={() => setModalOpen(true)}>
            <Group gap={3}>
              <Text fz="sm" c="dimmed">
                View Webhook JSON Response
              </Text>
              <IconChevronRight color="gray" stroke={1} size={18} />
            </Group>
          </UnstyledButton>
        </Box>
      </>
    );

  const renderObject = (obj) => {
    // Check if obj is undefined or null
    if (!obj) {
      return <Text>No data available</Text>;
    }

    return (
      <Box>
        {Object.keys(obj).map((key) => {
          const value = obj[key];
          // Check if value is an object and not null
          return (
            <Text key={key}>
              {key}:{" "}
              {typeof value === "object" && value !== null
                ? renderObject(value)
                : value}
            </Text>
          );
        })}
      </Box>
    );
  };

  if (isLoading) {
    return <LoadingOverlay visible={true} />;
  }

  if (!data) {
    return (
      <Container>
        <Flex
          style={{ height: "600px" }}
          align="center"
          justify="center"
          direction="column"
        >
          <Stack align="center">
            <Lottie
              loop={false}
              animationData={status === "PASS" ? lottieSuccess : lottieFailure}
              play
              style={{ width: 350, height: 350 }}
            />
            <Text fz={40} fw={700} align="center">
              {status === "PASS" ? "Verification Success" : "Whoops!"}
            </Text>
            <Text fz="lg" align="center" c="dimmed">
              {status === "PASS"
                ? "You have been verified and may proceed."
                : "Something went wrong and we were unable to verify you."}
            </Text>
            <Box mt="lg" onClick={fetchStatus} style={{ cursor: "pointer" }}>
              <Stack align="center" gap={0}>
                <Text>Verification Result Data</Text>
                <IconChevronCompactDown size={30} stroke={1} />
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </Container>
    );
  }

  return (
    <>
      <Container fluid pb={100}>
        {data && (
          <>
            <Grid mt="lg" grow>
              <Grid.Col span={7} gutter="xl">
                <Paper p="xl" withBorder>
                  <Group gap="md">
                    <Badge
                      color={data.status === "PASS" ? "teal" : "orange"}
                      radius="sm"
                      size="xl"
                      //variant="light"
                    >
                      {data.status}
                    </Badge>
                    <Text fz={25} fw={500} tt="uppercase">
                      {data.fn} {data.ln}
                    </Text>
                  </Group>

                  <SimpleGrid cols={2} pt="xl">
                    <DataDisplay label="Date of Birth" value={data.dob} />
                    <DataDisplay label="ID Number" value={"PRIVATE"} />
                    <DataDisplay label="Phone" value={data.phone} />
                    <DataDisplay label="Email" value={data.email} />
                    <DataDisplay label="Address" value={data.addr} />
                    <DataDisplay label="City" value={data.city} />
                    <DataDisplay label="State" value={data.state} />
                    <DataDisplay label="Zip" value={data.zip} />
                  </SimpleGrid>
                </Paper>

                <Paper withBorder p="xl" mt="lg">
                  <Text mb="lg">Add-ons</Text>
                  <SimpleGrid cols={2} pl="xl">
                    <AddOnIndicator
                      addonName="vPin"
                      label="V-PIN"
                      icon={<IconTargetArrow size={35} stroke={1} />}
                    />
                    <AddOnIndicator
                      addonName="bouncer"
                      label="Bouncer"
                      icon={<IconHandStop size={35} stroke={1} />}
                    />
                    <AddOnIndicator
                      addonName="fraudScore"
                      label="Fraud Score"
                      icon={<IconAnalyze size={35} stroke={1} />}
                    />
                    <AddOnIndicator
                      addonName="phoneInsights"
                      label="Phone Validity"
                      icon={<IconPhoneCheck size={35} stroke={1} />}
                    />
                  </SimpleGrid>
                  <Box align="center" mt="lg">
                    <UnstyledButton
                      size="sm"
                      onClick={() => setAddOnModalOpen(true)}
                    >
                      <Group gap={3}>
                        <Text fz="sm" c="dimmed">
                          View Full Add-on Response
                        </Text>
                        <IconChevronRight color="gray" stroke={1} size={18} />
                      </Group>
                    </UnstyledButton>
                  </Box>
                </Paper>
              </Grid.Col>
              <Grid.Col span={5}>
                <Paper p="xl" withBorder>
                  {journeyTimeline(data)}
                </Paper>
              </Grid.Col>
            </Grid>

            <Modal
              opened={isModalOpen}
              onClose={() => setModalOpen(false)}
              title="Raw JSON Response"
              centered
              size="lg"
            >
              <Code block>{JSON.stringify(data, null, 2)}</Code>
            </Modal>
            <Modal
              opened={isAddOnModalOpen}
              onClose={() => setAddOnModalOpen(false)}
              title="Raw JSON Response"
              centered
              size="lg"
            >
              <Code block>{JSON.stringify(addOnData, null, 2)}</Code>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
}
