import React from "react";
// core styles are required for all packages
import "@mantine/core/styles.css";

// other css files are required only if
// you are using components from the corresponding package
// import '@mantine/dates/styles.css';
// import '@mantine/dropzone/styles.css';
// import '@mantine/code-highlight/styles.css';
// ...
import logo from "./logo.svg";
import "./App.css";
import {
  Box,
  Text,
  MantineProvider,
  Image,
  Container,
  Button,
  createTheme,
} from "@mantine/core";
import Routes from "./routes.js";
import MainAppShell from "./app-shell.js";
import { GlobalProvider } from "./context/global-context";

const theme = createTheme({
  colors: {
    "ocean-blue": [
      "#B3D1FC",
      "#9BC3FA",
      "#82B5F8",
      "#6AA8F6",
      "#529BF3",
      "#3A8EF1",
      "#227FF0",
      "#096FFD",
      "#0060EA",
      "#0056D7",
    ],
    midnight: [
      "#1c1c1e", // A very dark gray, almost black
      "#2c2c2e", // A slightly lighter shade of very dark gray
      "#3c3c3e", // A dark gray with a hint of lightness
      "#4c4c4e", // Medium-dark gray
      "#5c5c5e", // Standard gray
      "#6c6c6e", // Medium-light gray
      "#1c1c1e", // Light gray
      "#8c8c8e", // Lighter gray
      "#9c9c9e", // Very light gray
      "#acacb0", // Lightest gray before white
    ],
    "bright-pink": [
      "#F0BBDD",
      "#ED9BCF",
      "#EC7CC3",
      "#ED5DB8",
      "#F13EAF",
      "#F71FA7",
      "#FF00A1",
      "#E00890",
      "#C50E82",
      "#AD1374",
    ],
  },
  primaryColor: "midnight",
  defaultRadius: 5,
  breakpoints: {
    xs: "36em",
    sm: "48em",
    md: "62em",
    lg: "75em",
    xl: "88em",
  },
});
function App() {
  return (
    <GlobalProvider>
      <MantineProvider theme={theme}>
        <MainAppShell content={<Routes />} />
      </MantineProvider>
    </GlobalProvider>
  );
}

export default App;
