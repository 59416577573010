import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Modal,
  Text,
  Button,
  Stack,
  Group,
  Select,
  Switch,
  Paper,
  Checkbox,
  RadioGroup,
  Radio,
  Accordion,
  NumberInput,
  ThemeIcon,
  SegmentedControl,
  Tooltip,
  SimpleGrid,
} from "@mantine/core";
import {
  IconBolt,
  IconCalendarBolt,
  IconCheck,
  IconFaceId,
  IconId,
  IconInputCheck,
  IconPhoneCheck,
} from "@tabler/icons-react";
import SelectOptionComponent from "./combobox-dropdown";
import { useGlobal } from "../context/global-context";
import RouteModal from "./route-modal";

export default function DemoglobalState() {
  const { globalState, setContextValue, updateAddons, updateCustomerFields } =
    useGlobal();
  const navigate = useNavigate();
  const location = useLocation();
  const [isAgeModalOpened, setIsAgeModalOpened] = useState(false);
  const [isLinearModalOpened, setIsLinearModalOpened] = useState(false);
  const [isFieldsModalOpened, setIsFieldsModalOpened] = useState(false);

  // Handler to update global state
  const handleChange = (field, value) => {
    if (field === "journeyType" && value === "linear") {
      //setIsLinearModalOpened(true);
    }
    setContextValue(field, value);
  };

  const handleglobalStateChange = (key, value) => {
    if (key === "journeyType" && value === "modular") {
      setContextValue((prev) => ({ ...prev, ["journey"]: "modularAV" }));
    }
    setContextValue((prev) => ({ ...prev, [key]: value }));
  };

  const handleAddonsChange = (addonKey) => {
    const addon = globalState.addons[addonKey];
    updateAddons(addonKey, { ...addon, checked: !addon.checked });
  };

  const handleCustomerDataChange = (dataKey, value = null) => {
    const dataField = globalState.customerData[dataKey];
    updateCustomerFields(dataKey, {
      ...dataField,
      checked: !dataField.checked,
      value: value,
    });
  };

  const handleCheckboxChange = (event, parentValue = null) => {
    const { value, checked } = event.target;
    let newChoices = [...globalState.modularChoices];

    if (!checked && !parentValue) {
      // If unchecking a parent, remove the parent and any children of this parent
      newChoices = newChoices.filter(
        (choice) =>
          choice !== value && !(choice.parent && choice.parent === value)
      );
    }

    if (checked) {
      if (parentValue) {
        // This is a child selection
        // Remove the parent, if it exists
        newChoices = newChoices.filter(
          (choice) =>
            choice !== parentValue &&
            !(choice.parent && choice.parent === parentValue)
        );
        // Add this child, ensuring no duplicate entries
        const childToAdd = { parent: parentValue, value: value };
        if (
          !newChoices.some(
            (choice) => choice.parent === parentValue && choice.value === value
          )
        ) {
          newChoices.push(childToAdd);
        }
      } else {
        // This is a parent selection
        // Remove any existing children of this parent from the array
        newChoices = newChoices.filter((choice) => choice.parent !== value);
        // Add the parent if it's not already present and none of its children are selected
        if (
          !newChoices.includes(value) &&
          !newChoices.some((choice) => choice.parent === value)
        ) {
          newChoices.push(value);
        }
      }
    } else {
      // Unchecking
      if (parentValue) {
        // Remove this child
        newChoices = newChoices.filter(
          (choice) => !(choice.parent === parentValue && choice.value === value)
        );
      } else {
        // Remove this parent
        newChoices = newChoices.filter((choice) => choice !== value);
      }
    }

    handleChange("modularChoices", newChoices);
  };

  // Assuming you have a mechanism to capture child option selection, e.g., within your Select component onChange
  const handleChildOptionChange = (parentValue, childValue) => {
    // Directly use handleCheckboxChange with an event-like object to simulate selection
    handleCheckboxChange(
      { target: { value: childValue, checked: true } },
      parentValue
    );
  };

  const modularOptions = [
    { value: "coppa", label: "Data Verification", active: true },
    { value: "kba", label: "Knowledge Based Authentication", active: false },
    { value: "phone", label: "Phone", active: true },
    { value: "estimation", label: "Facial Age Estimation", active: true },
    {
      value: "id_doc_express_options",
      label: "Identity Document",
      active: true,
    },
  ];

  const optionsWithChildren = {
    coppa: [
      { value: "coppa", label: "Last 4 SSN", disabled: false },
      {
        value: "dob_address",
        label: "Address and Date of Birth",
        disabled: true,
      },
      {
        value: "coppa_idrCalc",
        label: "COPPA Compliance (IDRCalc)",
        disabled: true,
      },
      { value: "kyc", label: "KYC/CIP Check", disabled: true },
    ],
    phone: [
      { value: "phone", label: "Smart2FA SMS", disabled: false },
      { value: "phone_smart2FAAuth", label: "Smart2FA Auth", disabled: true },
      {
        value: "phonematch_noPossession",
        label: "Phone Data Check",
        disabled: true,
      },
    ],
    id_doc_express_options: [
      {
        value: "id_doc_express_options",
        label: "Take a picture with express options",
        disabled: false,
      },
      { value: "id_doc_basic", label: "Take a picture only", disabled: false },
      { value: "id_doc_w_selfie", label: "Selfie Match", disabled: true },
    ],
    estimation: [],
    kba: [],
  };

  const validateAgeModal = () => {
    const {
      customAgeValue,
      customAgeRangeStart,
      customAgeRangeEnd,
      customAgeType,
    } = globalState;

    // Check if ageType is selected
    if (!customAgeType) {
      alert("Please select an Age Type.");
      return false;
    }

    // Check if Custom Age has a value when ageType is not 'range'
    if (customAgeType !== "range" && !customAgeValue) {
      alert("Please enter a Custom Age value.");
      return false;
    }

    // Check if both Custom Age Range Start and Custom Age Range End have values when ageType is 'range'
    if (customAgeType === "range" && (!customAgeValue || !customAgeRangeEnd)) {
      alert("Please enter both start and end values for the age range.");
      return false;
    }

    if (customAgeType === "range" && customAgeValue >= customAgeRangeEnd) {
      alert("Start age must be before end");
      return false;
    }

    return true;
  };

  return (
    <>
      <Stack gap="md">
        <Accordion
          chevronPosition="right"
          variant="contained"
          mt={0}
          //defaultValue={"Route"}
        >
          <Accordion.Item
            value="Route"
            label="Route"
            style={{ backgroundColor: "white" }}
          >
            <Accordion.Control fz="sm">Route</Accordion.Control>
            <Accordion.Panel>
              <Stack gap={25}>
                <Paper style={{ backgroundColor: "#fafafa" }} withBorder p="sm">
                  <Stack gap={5}>
                    <Text fz="xs" c="dimmed">
                      Current Route
                    </Text>
                    <Text fz="sm" fw={700}>
                      {globalState.journeyTitle}
                    </Text>
                  </Stack>
                </Paper>

                <Button
                  size="xs"
                  fullWidth
                  onClick={() => setContextValue("isRouteModalOpened", true)}
                >
                  Change Route
                </Button>
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item
            value="Age to Check"
            label="Age to Check"
            style={{ backgroundColor: "white" }}
          >
            <Accordion.Control fz="sm">Age to Check</Accordion.Control>
            <Accordion.Panel>
              <Select
                label="Age Requirement"
                value={globalState.age}
                disabled={!globalState.isAgeActive || globalState.isAgeCustom}
                onChange={(value) => handleChange("age", value)}
                data={[
                  { label: "13+", value: "13+" },
                  { label: "18+", value: "18+" },
                  { label: "21+", value: "21+" },
                  { label: "25+", value: "25+" },
                ]}
              />
              <Group justify="space-between" mt="xs" wrap="nowrap">
                <Switch
                  label="Check age"
                  size="xs"
                  defaultChecked={globalState.isAgeActive}
                  onChange={(event) =>
                    handleChange("isAgeActive", event.currentTarget.checked)
                  }
                />
                <Group position="flex-end" gap={0} wrap="nowrap">
                  {globalState.isAgeCustom && (
                    <IconCheck color="green" stroke={1} />
                  )}
                  <Text
                    fz="xs"
                    c="dimmed"
                    td="underline"
                    align="right"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsAgeModalOpened(true)}
                  >
                    {!globalState.isAgeCustom
                      ? "Set custom age"
                      : "Age is custom"}
                  </Text>
                </Group>
              </Group>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item
            value="Language"
            label="Language"
            style={{ backgroundColor: "white" }}
          >
            <Accordion.Control fz="sm">Localization</Accordion.Control>
            <Accordion.Panel>
              <Select
                label="Select a language"
                searchable
                value={globalState.language}
                onChange={(value) => handleChange("language", value)}
                data={[
                  { label: "Afrikaans", value: "af" },
                  { label: "Amharic", value: "am" },
                  { label: "Arabic", value: "ar" },
                  { label: "Azerbaijani", value: "az" },
                  { label: "Belarusian", value: "be" },
                  { label: "Bulgarian", value: "bg" },
                  { label: "Bengali", value: "bn" },
                  { label: "Bosnian", value: "bs" },
                  { label: "Catalan", value: "ca" },
                  { label: "Czech", value: "cs" },
                  { label: "Danish", value: "da" },
                  { label: "German", value: "de" },
                  { label: "Divehi", value: "dv" },
                  { label: "Greek", value: "el" },
                  { label: "English", value: "en" },
                  { label: "Spanish", value: "es" },
                  { label: "Estonian", value: "et" },
                  { label: "Persian", value: "fa" },
                  { label: "Finnish", value: "fi" },
                  { label: "French", value: "fr" },
                  { label: "Irish", value: "ga" },
                  { label: "Hebrew", value: "he" },
                  { label: "Hindi", value: "hi" },
                  { label: "Croatian", value: "hr" },
                  { label: "Hungarian", value: "hu" },
                  { label: "Armenian", value: "hy" },
                  { label: "Indonesian", value: "id" },
                  { label: "Icelandic", value: "is" },
                  { label: "Italian", value: "it" },
                  { label: "Japanese", value: "ja" },
                  { label: "Georgian", value: "ka" },
                  { label: "Kazakh", value: "kk" },
                  { label: "Khmer", value: "km" },
                  { label: "Korean", value: "ko" },
                  { label: "Kyrgyz", value: "ky" },
                  { label: "Lao", value: "lo" },
                  { label: "Lithuanian", value: "lt" },
                  { label: "Latvian", value: "lv" },
                  { label: "Macedonian", value: "mk" },
                  { label: "Mongolian", value: "mn" },
                  { label: "Malay", value: "ms" },
                  { label: "Maltese", value: "mt" },
                  { label: "Burmese", value: "my" },
                  { label: "Nepali", value: "ne" },
                  { label: "Dutch", value: "nl" },
                  { label: "Norwegian", value: "no" },
                  { label: "Polish", value: "pl" },
                  { label: "Pashto", value: "ps" },
                  { label: "Portuguese", value: "pt" },
                  { label: "Portuguese (Brazil)", value: "pt-br" },
                  { label: "Romanian", value: "ro" },
                  { label: "Kinyarwanda", value: "rw" },
                  { label: "Sinhala", value: "si" },
                  { label: "Slovak", value: "sk" },
                  { label: "Slovenian", value: "sl" },
                  { label: "Samoan", value: "sm" },
                  { label: "Somali", value: "so" },
                  { label: "Albanian", value: "sq" },
                  { label: "Serbian", value: "sr" },
                  { label: "Swedish", value: "sv" },
                  { label: "Swahili", value: "sw" },
                  { label: "Tajik", value: "tg" },
                  { label: "Thai", value: "th" },
                  { label: "Tigrinya", value: "ti" },
                  { label: "Turkish", value: "tr" },
                  { label: "Ukrainian", value: "uk" },
                  { label: "Urdu", value: "ur" },
                  { label: "Uzbek", value: "uz" },
                  { label: "Vietnamese", value: "vi" },
                  { label: "Chinese", value: "zh" },
                  { label: "Chinese (Simplified)", value: "zh-cn" },
                  { label: "Chinese (Traditional)", value: "zh-tw" },
                ]}
              />
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item
            value="Integration Options"
            label="Integration Options"
            style={{ backgroundColor: "white" }}
          >
            <Accordion.Control fz="sm">Integration Options</Accordion.Control>

            <Accordion.Panel>
              <Stack>
                <SegmentedControl
                  value={globalState.method}
                  onChange={(value) => handleChange("method", value)}
                  data={[
                    { label: "Popup", value: "popup" },
                    { label: "Redirect", value: "redirect" },
                  ]}
                />
                <Group>
                  <Switch
                    size="sm"
                    defaultChecked={globalState.isCollectCustomerData}
                    onChange={(event) =>
                      handleChange(
                        "isCollectCustomerData",
                        event.currentTarget.checked
                      )
                    }
                    label={
                      <Tooltip
                        multiline
                        withArrow
                        w={220}
                        position="right-end"
                        label="Client applications that have already collected customer data can pass this data into the VX session to avoid collection redundancy"
                      >
                        <Text fz="sm">Data Collection</Text>
                      </Tooltip>
                    }
                  />
                  {globalState.isCollectCustomerData && (
                    <Text
                      fz="xs"
                      c="dimmed"
                      td="underline"
                      //align="right"
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsFieldsModalOpened(true)}
                    >
                      Manage fields
                    </Text>
                  )}
                </Group>
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item label="Add-Ons" value="add-ons">
            <Accordion.Control fz="sm">Add-ons</Accordion.Control>
            <Accordion.Panel>
              <Stack>
                {Object.entries(globalState.addons).map(
                  ([key, { label, description, active, checked }]) => (
                    <Group key={key} position="space-between" grow>
                      <Tooltip label={description}>
                        <Text fz="sm">{label}</Text>
                      </Tooltip>
                      <Switch
                        size="sm"
                        disabled={!active}
                        checked={checked}
                        onChange={() => handleAddonsChange(key)}
                      />
                    </Group>
                  )
                )}
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Stack>
      {globalState.isRouteModalOpened && <RouteModal />}
      <Modal
        size="sm"
        opened={isFieldsModalOpened}
        onClose={() => setIsFieldsModalOpened(false)}
        title={
          <>
            <Group>
              <ThemeIcon size="lg">
                <IconInputCheck stroke={1.5} />
              </ThemeIcon>
              <Text size="lg" weight={700}>
                Data Collection Fields
              </Text>
            </Group>
          </>
        }
        centered
      >
        <Text fz="sm">
          Choose the fields to collect and pass into the VX session:
        </Text>
        <Stack p="lg">
          <SimpleGrid cols={2}>
            {Object.entries(globalState.customerData).map(
              ([key, { label, checked }]) => (
                <Checkbox
                  key={key}
                  label={label}
                  checked={checked}
                  onChange={() => handleCustomerDataChange(key)}
                />
              )
            )}
          </SimpleGrid>
          <Button
            mt="xl"
            onClick={() => {
              setIsFieldsModalOpened(false);
            }}
          >
            Save
          </Button>
        </Stack>
      </Modal>
      <Modal
        opened={isAgeModalOpened}
        onClose={() => setIsAgeModalOpened(false)}
        title={
          <>
            <Group>
              <ThemeIcon size="lg">
                <IconCalendarBolt stroke={1.5} />
              </ThemeIcon>
              <Text fz="lg" fw={700}>
                Custom Age Check
              </Text>
            </Group>
          </>
        }
        centered
      >
        <Stack>
          <Select
            label="Age Type"
            placeholder="Select an age check type"
            value={globalState.customAgeType}
            onChange={(value) => handleChange("customAgeType", value)}
            data={[
              { label: ">= Greater than equal to", value: "plus" },
              { label: "<= Less than equal to", value: "minus" },
              { label: "== Equal to", value: "equal" },
              { label: "<> Range", value: "range" },
            ]}
          />
          <NumberInput
            label="Custom Age"
            value={globalState.customAgeValue}
            onChange={(value) => handleChange("customAgeValue", value)}
            placeholder={
              globalState.customAgeType === "range"
                ? "Start age range"
                : "Custom age value"
            }
          />
          {globalState.customAgeType === "range" && (
            <NumberInput
              value={globalState.customAgeRangeEnd}
              onChange={(value) => handleChange("customAgeRangeEnd", value)}
              placeholder="End age range"
            />
          )}

          <Button
            onClick={() => {
              if (validateAgeModal()) {
                handleChange("isAgeCustom", true);
                setIsAgeModalOpened(false);
              }
            }}
          >
            Save
          </Button>
          <Button
            variant="subtle"
            onClick={() => {
              handleChange("isAgeCustom", false);
              setIsAgeModalOpened(false);
              handleChange("customAgeRangeEnd", "");
              handleChange("customAgeValue", "");
              handleChange("customAgeType", "");
            }}
          >
            Clear
          </Button>
        </Stack>
      </Modal>

      <Modal
        opened={isLinearModalOpened}
        onClose={() => setIsLinearModalOpened(false)}
        title={
          <>
            <Group>
              <ThemeIcon size="lg">
                <IconCalendarBolt stroke={1.5} />
              </ThemeIcon>
              <Text fz="lg" fw={700}>
                Linear Journey Sample Options
              </Text>
            </Group>
          </>
        }
        centered
      >
        <Stack></Stack>
      </Modal>
    </>
  );
}
