import React from "react";
import {
  Paper,
  Text,
  Box,
  Button,
  Stack,
  Center,
  TextInput,
  Alert,
  Group,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import VerificationModal from "../../components/verification-modal";
import Banner from "../../assets/kids_games_hero.webp";
import { IconExclamationCircle, IconShieldBolt } from "@tabler/icons-react";

export default function KidsGames() {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Box
        style={{
          height: "100vh",
          backgroundImage: `url(${Banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Center style={{ width: "100%" }}>
          <Paper
            style={{
              maxWidth: "500px",
              width: "100%",
              //background: "rgba(255, 255, 255, 0.8)",
              padding: "40px",
            }}
            shadow="xl"
          >
            <Stack gap="xl" mb="xl">
              <Stack gap={8}>
                <Group>
                  <IconShieldBolt size={40} />
                  <Text fz={30} fw={700}>
                    Verification Required
                  </Text>
                </Group>
                <Text size="md" color="dimmed">
                  Your child is interested in signing up for Galactic Pals.
                  Before proceeding, we require your permission and need to
                  confirm that you are an adult.
                </Text>
              </Stack>

              <Button fullWidth onClick={open} color="indigo" size="lg">
                START VERIFICATION
              </Button>
            </Stack>
            <Alert
              icon={<IconExclamationCircle />}
              title="Online Demo Kids Game"
            >
              Here is an example of how to integrate Veratad VX into a
              children's game that requires age assurance.
            </Alert>
          </Paper>
        </Center>
      </Box>
      <VerificationModal
        title="Verify Your Identity"
        skip={true}
        description="You must first verify your identity before you can create an account"
        opened={opened}
        close={close}
      />
    </>
  );
}
