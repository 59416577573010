import React, { useEffect, useState } from "react";
import { Avatar, Image } from "@mantine/core";
import ComboboxDropdown from "./combobox-dropdown";
import WineLogo from "../assets/wine_logo.png";
import FintechLogo from "../assets/fintech_logo.png";
import Acme from "../assets/acme-logo.png";
import KidsGamesLogo from "../assets/kids-game-logo.webp";
import { useGlobal } from "../context/global-context";
export default function ClientApplicationToggle() {
  const { globalState, setContextValue } = useGlobal();

  const [selectedClientApp, setSelectedClientApp] = useState("");

  // Effect to load the initial state from localStorage when component mounts
  useEffect(() => {
    const storedApp = localStorage.getItem("selectedClientApp");
    if (storedApp) {
      setSelectedClientApp(storedApp);
      setContextValue("selectedClientApp", storedApp);
      if (storedApp === "/kids-games") {
        setContextValue("journey", "coppa_only");
        setContextValue("journeyTitle", "Data Match COPPA");
      } else {
        setContextValue("journey", "modularAV");
        setContextValue("journeyTitle", "ID Document with Express Options");
      }
    }
  }, []);

  const handleClientApplicationChange = (value) => {
    // Update local state
    setSelectedClientApp(value);
    // Save to localStorage to persist across sessions
    localStorage.setItem("selectedClientApp", value);
    setContextValue("selectedClientApp", value);
    if (value === "/kids-games") {
      setContextValue("journey", "coppa_only");
      setContextValue("journeyTitle", "Data Match COPPA");
    } else {
      setContextValue("journey", "modularAV");
      setContextValue("journeyTitle", "ID Document with Express Options");
    }
  };

  const data = [
    {
      label: "Age Restricted Commerce",
      value: "/wine",
      description: "Sample online wine store",
      emoji: <Image src={WineLogo} fit={"contain"} w={55} />,
    },
    {
      label: "Online Financial Services",
      value: "/register",
      description: "Sample registration with IDMax Buttons and VX",
      emoji: <Image radius="lg" src={Acme} fit={"contain"} w={55} />,
    },
    {
      label: "Online Kids Games",
      value: "/kids-games",
      description: "Sample registration for a kids game",
      emoji: <Image radius="xl" src={KidsGamesLogo} fit={"contain"} w={55} />,
    },
  ];

  return (
    <ComboboxDropdown
      withBorder={false}
      data={data}
      defaultValue={globalState.selectedClientApp}
      onChange={handleClientApplicationChange}
    />
  );
}
