import React, { useState, useEffect } from "react";
import { ActionIcon, Tooltip, useMantineTheme, Box } from "@mantine/core";
import {
  IconChevronRight,
  IconChevronLeft,
  IconMinus,
} from "@tabler/icons-react";
import { useGlobal } from "../context/global-context";

export default function SidebarToggleIcon({
  isOpened,
  toggleSidebar,
  navbarWidth,
  onIconHoover,
}) {
  const { globalState } = useGlobal();
  const theme = useMantineTheme();
  const [hover, setHover] = useState(false); // State to track hover

  const iconStyle = {
    cursor: "pointer",
    position: "fixed",
    backgroundColor: "white",
    borderRadius: "8px",
    zIndex: 1000, // Ensure it's above other content
    top: "50%", // Center vertically
    left: isOpened ? `${navbarWidth + 10}px` : "10px", // Dynamically set based on isOpened
    transform: "translateY(-50%)",
    transition: "left 0.3s ease, transform 0.3s ease", // Smooth transition
  };

  // Style for the vertical bar, adjust width/height/bgColor as needed
  const verticalBarStyle = {
    height: "18px",
    width: "2px",
    backgroundColor: theme.colors.dark[1],
    transition: "transform 1s ease",
  };

  useEffect(() => {
    if (hover) {
      onIconHoover(true);
    } else {
      onIconHoover(false);
    }
  }, [hover]);

  if (!globalState.isSidebarShown) {
    return <></>;
  }

  return (
    <Box
      style={iconStyle}
      onClick={toggleSidebar}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      visibleFrom="sm"
    >
      <Tooltip
        label={isOpened ? "Close Settings" : "Open Settings"}
        color="black"
        position="right-end"
        withArrow
        arrowPosition="center"
      >
        <ActionIcon variant="subtle">
          {isOpened ? (
            hover ? (
              <IconChevronLeft size={24} color={theme.colors.dark[1]} />
            ) : (
              <div style={verticalBarStyle}></div> // Vertical bar representation
            )
          ) : (
            <IconChevronRight size={24} color={theme.colors.dark[1]} />
          )}
        </ActionIcon>
      </Tooltip>
    </Box>
  );
}
