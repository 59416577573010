import React from "react";
import {
  Paper,
  Text,
  Image,
  Box,
  Button,
  SimpleGrid,
  Stack,
  Group,
  Container,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import VerificationModal from "../../components/verification-modal";
import WineOne from "../../assets/wine_one.png";
import WineTwo from "../../assets/wine_two.png";
import WineBanner from "../../assets/wine_banner.png";
import WineThree from "../../assets/wine_three.png";
const inventory = [
  { name: "Moon White", image: WineOne, cost: "100" },
  { name: "Starse Rosé", image: WineTwo, cost: "150" },
  { name: "Sunset Red", image: WineThree, cost: "200" },
  { name: "Chardonnay", image: WineOne, cost: "250" },
];

export default function Home() {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <Container size="xl">
        <Paper withBorder p="lg" style={{ backgroundColor: "#c4bfa7" }}>
          <Group justify="space-between" p="xl">
            <Stack gap={0}>
              <Text fw={700} fz={30}>
                Online Demo Store
              </Text>
              <Text fz={20}>Age Restricted Commerce</Text>
            </Stack>

            <Image
              h={200}
              src={WineBanner}
              alt={"wine banner image"}
              radius="xl"
            />
          </Group>
        </Paper>
        <SimpleGrid
          mt="lg"
          cols={{ base: 1, sm: 2 }}
          spacing={{ base: 10, sm: "xl" }}
          verticalSpacing={{ base: "md", sm: "xl" }}
        >
          {inventory.map((item, index) => (
            <Paper key={index} shadow="xs" p="md" withBorder>
              <Group justify="space-between">
                <Group>
                  <Image w={100} src={item.image} alt={item.name} radius="lg" />

                  <Stack align="left" gap={0}>
                    <Text fz="xl" fw={700}>
                      {item.name}
                    </Text>
                    <Text>Cost: ${item.cost}</Text>
                  </Stack>
                </Group>
                <Button onClick={open}>Add to Cart</Button>
              </Group>
            </Paper>
          ))}
        </SimpleGrid>
        <VerificationModal
          title="Verify Your Age"
          description="You must first verify your age before you can make a purchase"
          opened={opened}
          close={close}
        />
      </Container>
    </>
  );
}
