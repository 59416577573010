import React from "react";
import {
  Paper,
  Text,
  Image,
  Box,
  Button,
  SimpleGrid,
  Stack,
  Group,
  Container,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import VerificationModal from "../../components/verification-modal";
import FinTechOne from "../../assets/fintech_one.png";
//import FinTechTwo from "../../assets/fintech_two.png";
//import FinTechThree from "../../assets/fintech_three.png";
const inventory = [
  { name: "Sign Up For an Account", image: FinTechOne, cost: "100" },
];

export default function Home() {
  const [opened, { open, close }] = useDisclosure(false);
  return (
    <>
      <Container size={1500} mt="xl">
        <Paper withBorder p="lg" style={{ backgroundColor: "#fefefe" }}>
          <Group justify="space-between" p="xl">
            <Stack gap={0}>
              <Text fw={700} fz={30}>
                Demo Online Financial Services
              </Text>

              <Button onClick={open} color="grape" mt="xl" size="xl">
                Open an Account
              </Button>
            </Stack>
            <Box p="xl">
              <Image
                h={450}
                src={FinTechOne}
                alt={"fintech banner"}
                //radius="lg"
              />
            </Box>
          </Group>
        </Paper>
      </Container>
      <VerificationModal
        title="Verify Your Identity"
        description="You must first verify your identity before you can create an account"
        opened={opened}
        close={close}
      />
    </>
  );
}
