import {
  IconBolt,
  IconCalendarBolt,
  IconFaceId,
  IconId,
  IconPhoneCheck,
} from "@tabler/icons-react";
import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Group } from "@mantine/core";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    selectedClientApp: localStorage.getItem("selectedClientApp")
      ? localStorage.getItem("selectedClientApp")
      : "/register",
    journey: "modularAV",
    journeyTitle: "ID Document with Express Options",
    journeyType: "linear",
    age: "21+",
    language: "en",
    idmaxCountry: "US",
    isAgeActive: true,
    isAgeCustom: false,
    customAgeType: "",
    customAgeValue: "",
    isCollectCustomerData: false,
    isSidebarShown: true,
    isRouteModalOpened: false,
    customerData: {
      fn: {
        label: "First Name",
        key: "fn",
        checked: false,
        value: null,
      },
      ln: {
        label: "Last Name",
        key: "ln",
        checked: false,
        value: null,
      },
      addr: {
        label: "Street Address",
        key: "addr",
        checked: false,
        value: null,
      },
      city: {
        label: "City",
        key: "city",
        checked: false,
        value: null,
      },
      state: {
        label: "State",
        key: "state",
        checked: false,
        value: null,
      },
      zip: {
        label: "Zip",
        key: "ln",
        checked: false,
        value: null,
      },
      dob: {
        label: "Date of Birth",
        key: "dob",
        checked: false,
        value: null,
      },
      ssn: {
        label: "SSN",
        key: "ssn",
        checked: false,
        value: null,
      },
      phone: {
        label: "Phone",
        key: "phone",
        checked: false,
        value: null,
      },
      email: {
        label: "Email",
        key: "email",
        checked: false,
        value: null,
      },
    },
    journeys: [
      {
        value: "data_match_to_id_doc",
        types: ["data match", "identity document"],
        title: "Data Match to ID Document",
        description: "Runs a data match and on failure ID Document",
      },
      {
        value: "agematch_address_dob",
        types: ["data match"],
        title: "AgeMatch Address and DOB",
        description: "Runs an AgeMatch check with address and DOB",
      },
      {
        value: "agematch_address_dob_doc",
        types: ["data match", "identity document"],
        title: "AgeMatch Address and DOB to ID Scan",
        description:
          "Runs an AgeMatch check with address and DOB and then ID scan",
      },
      {
        value: "phone_agematch_address_dob_dcams_express",
        types: ["data match", "identity document", "phone", "express"],
        title: "Phone, Age w/Address, DCAMS w/Express",
        description: "Phone to Age with address and then DCAMS with express",
      },
      {
        value: "coppa_only",
        types: ["data match"],
        title: "Data Match COPPA",
        description: "Runs an ID document with selfie only",
      },
      {
        value: "kba_only",
        types: ["data match", "kba"],
        title: "KBA Only",
        description: "Runs a KBA process",
      },
      {
        value: "phone_only",
        types: ["phone"],
        title: "Phone Only",
        description: "Runs a Smart2FA only",
      },
      {
        value: "phone_to_id_doc_with_selfie",
        types: ["phone", "identity document", "face"],
        title: "Phone to ID Document With Selfie",
        description: "Runs a Smart2FA and on failure ID Document",
      },
      {
        value: "phone_to_id_doc_express",
        types: ["phone", "identity document", "express", "idmax"],
        title: "Phone to ID Document Express",
        description: "Runs a Smart2FA and on failure ID Express",
      },
      {
        value: "phone_data_doc",
        types: ["phone", "data match", "identity document"],
        title: "Phone to Data to ID Document",
        description: "Runs a Smart2FA, SSN and then ID Document",
      },
      {
        value: "id_doc",
        types: ["identity document"],
        title: "ID Document Only",
        description: "Runs an ID document only",
      },
      {
        value: "clear_only",
        types: ["identity document", "idmax", "express"],
        title: "IDMax CLEAR Only",
        description: "Runs a CLEAR only",
      },
      {
        value: "plaid_only",
        types: ["identity document", "idmax", "express"],
        title: "IDMax PLAID Only",
        description: "Runs a PLAID only",
      },
      {
        value: "digilocker_only",
        types: ["identity document", "idmax", "express"],
        title: "IDMax Digilocker Only",
        description: "Runs a Digilocker only",
      },
      {
        value: "id_doc_w_express",
        types: ["express", "identity document", "idmax"],
        title: "ID Document with Express Options",
        description: "Runs an ID document with express options",
      },
      {
        value: "id_doc_w_selfie",
        types: ["face", "identity document"],
        title: "ID Document with Selfie Only",
        description: "Runs an ID document with selfie only",
      },
      {
        value: "estimation_to_phone_to_id_doc",
        types: ["face", "phone", "identity document"],
        title: "Estimation to Phone to ID Document",
        description:
          "Runs an estimation and then phone and then ID with Express",
      },
    ],
    customAgeRangeEnd: null,
    method: "popup",
    modularChoices: ["id_doc_express_options"],
    addons: {
      fraudScore: {
        active: true,
        checked: true,
        label: "Fraud Score",
        description: "Returns a score of fraud probability",
      },
      vPin: {
        active: true,
        checked: true,
        label: "VPIN",
        description: "Provides a identification number for customers",
      },
      bouncer: {
        active: true,
        checked: true,
        label: "Bouncer",
        description:
          "ML model that identifies and blocks customers based on rules",
      },
      phoneInsights: {
        active: true,
        checked: true,
        label: "Phone Insights",
        description: "Gathers insights on phone numbers",
      },
      deviceInsights: {
        active: false,
        checked: false,
        label: "Device Insights",
        description: "Collects data on user devices",
      },
      location: {
        active: false,
        checked: false,
        label: "Location",
        description: "GPS location for fraud and compliance",
      },
    },
  });

  useEffect(() => {
    navigate(state.selectedClientApp);
  }, [state.selectedClientApp, navigate]);

  const setContextValue = (key, value) => {
    setState((prevState) => {
      if (prevState[key] === value) return prevState; // no change, don't update state
      return { ...prevState, [key]: value };
    });
  };

  const updateAddons = (key, updatedAddon) => {
    setState((prevState) => ({
      ...prevState,
      addons: {
        ...prevState.addons,
        [key]: updatedAddon,
      },
    }));
  };

  const updateCustomerFields = (key, updatedFields) => {
    setState((prevState) => ({
      ...prevState,
      customerData: {
        ...prevState.customerData,
        [key]: updatedFields,
      },
    }));
  };

  const contextValue = {
    globalState: state,
    setContextValue,
    updateAddons,
    updateCustomerFields,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);
