import React, { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  AppShell,
  Burger,
  Group,
  Skeleton,
  Image,
  ScrollArea,
  Center,
  Stack,
  Text,
  Container,
  useMantineTheme,
  AspectRatio,
  Overlay,
  Button,
  Mark,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import DemoSettings from "./components/demo-settings";
import { useGlobal } from "./context/global-context";
import VXLogo from "./assets/vx-full-logo.svg";
import ClientApplicationToggle from "./components/client-application-toggle";
import SidebarToggleIcon from "./components/sidebar-toggle";
import { IconRefresh, IconUserCircle } from "@tabler/icons-react";
export default function MainAppShell({ content }) {
  const { globalState } = useGlobal();
  const theme = useMantineTheme();
  const location = useLocation(); // Use useLocation
  const navigate = useNavigate();
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(false);
  const searchParams = new URLSearchParams(location.search);
  const [clientApplication, setClientApplication] = useState(
    `${location.pathname}?${searchParams.toString()}`
  );
  const [iconHoover, setIconHoover] = useState(false);
  const navbarWidth = 350;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Add a class or style conditionally based on dropdownOpen state
  const appShellStyle = dropdownOpen
    ? { filter: "blur(4px)", backgroundColor: "red" }
    : {};

  useEffect(() => {
    if (mobileOpened || desktopOpened) {
      toggleMobile(false);
      toggleDesktop(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    console.log("drop down open ", dropdownOpen);
  }, [dropdownOpen]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // Construct the updated URL with the new query parameters
    const updatedUrl = `${clientApplication}?${searchParams.toString()}`;
    navigate(clientApplication);
    console.log("client app is", clientApplication);
  }, [clientApplication, navigate]);

  const handleIconHoover = (value) => {
    if (value) {
      setIconHoover(true);
    } else {
      setIconHoover(false);
    }
  };

  const handleRestart = () => {
    window.location.href = window.location.origin; // Navigates to the base URL of the current window
  };

  const isOpened = mobileOpened || desktopOpened; // Determine if either navbar is open

  const burgerStyle = {
    position: "fixed",
    zIndex: 1000, // Make sure it's above other content
    top: "50%", // Center vertically
    left: isOpened ? `${navbarWidth}px` : "0px", // Move out with the sidebar
    transform: "translateY(-50%)",
    transition: "left 0.3s ease", // Smooth transition
  };

  const toggleSidebar = () => {
    if (window.innerWidth < theme.breakpoints.sm) {
      toggleMobile();
    } else {
      toggleDesktop();
    }
  };

  useEffect(() => {
    // If the sidebar should not be shown, close it.
    if (!globalState.isSidebarShown) {
      if (mobileOpened) toggleMobile(false); // If mobileOpened is true, close it.
      if (desktopOpened) toggleDesktop(false); // If desktopOpened is true, close it.
    }
  }, [
    globalState.isSidebarShown,
    mobileOpened,
    desktopOpened,
    toggleMobile,
    toggleDesktop,
  ]);

  const handleDropDown = (isOpen) => {
    console.log("Dropdown state in MainAppShell:", isOpen);
    setDropdownOpen(isOpen);
  };

  return (
    <AppShell
      header={{ height: 100 }}
      navbar={{
        width: navbarWidth,
        breakpoint: "sm",
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
      //padding="md"
      style={appShellStyle}
    >
      <AppShell.Header
        withBorder={false}
        style={{ paddingLeft: "10px", paddingRight: "40px" }}
      >
        <Group h="100%" px="md" justify="space-between">
          <ClientApplicationToggle />

          <Group>
            <Button
              leftSection={<IconRefresh size={14} />}
              size="xs"
              color="black"
              onClick={handleRestart}
              variant="light"
            >
              Restart
            </Button>
          </Group>

          <Burger
            opened={mobileOpened}
            onClick={toggleMobile}
            hiddenFrom="sm"
            size="sm"
          />

          <SidebarToggleIcon
            onIconHoover={handleIconHoover}
            isOpened={isOpened}
            toggleSidebar={toggleSidebar}
            navbarWidth={navbarWidth} // Pass navbarWidth as a prop
            onDropdownToggle={handleDropDown}
          />
        </Group>
      </AppShell.Header>
      {globalState.isSidebarShown && (
        <AppShell.Navbar p="md">
          <AppShell.Section grow pb={40} component={ScrollArea}>
            <DemoSettings />
          </AppShell.Section>
          {iconHoover && (
            <Overlay color="#333333" backgroundOpacity={0.1} blur={1} />
          )}

          <AppShell.Section>
            <Center>
              <Stack align="center" gap={5}>
                <Text fz="xs" c="dimmed">
                  Powered by
                </Text>
                <Image src={VXLogo} w={120} />
              </Stack>
            </Center>
          </AppShell.Section>
        </AppShell.Navbar>
      )}
      <AppShell.Main style={{ backgroundColor: "#fafafa" }}>
        {content}
      </AppShell.Main>
    </AppShell>
  );
}
